.breadcrumb {
    margin-bottom: 32px;
}

.breadcrumbLink {
    font-size: 14px;
    line-height: 17px;
    color: $darkGrey;

    &:after {
        content: '>';
        margin: 0 5px;
    }

    &.active {
        font-weight: 600;
        color: $blue;

        &:after {
            display: none;
        }
    }
}