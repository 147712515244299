.inscriptionContainer {
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 75px 100px;

  @media only screen and (max-width: 767px) {
    padding: 45px 35px;
  }
}

.chat-wizzard-component {
  padding-left: 75px;

  @media only screen and (max-width: 1404px) {
    padding: 0;
  }

  .cw-intro {
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate( -100%, 0 );
      margin-left: -15px;

      @media only screen and (max-width: 1404px) {
        display: block;
        position: relative;
        transform: none;
        margin: 0 auto 35px auto;
      }
    }
  }

  .cw-step-row {
    position: relative;
  }

  .cw-question-bubble {
    display: inline-block;
    padding: 15px 20px;
    margin: 6px 0;
    border-radius: 0 8px 8px 8px;
    background: #f5f5f5;

    p {
      padding: 0;
      margin: 0;
      font-family: $fontLato;
      color: #231F20;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;

      @media only screen and (max-width: 545px) {
        font-size: 14px;
        line-height: 1.4;
      }
    }
  }

  .cw-choices-btns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 0;

    @media only screen and (max-width: 1404px) {
      display: block;
    }

    .choice-btn {
      display: inline-block;
      padding: 15px 20px;
      margin: 10px 20px 10px 0;
      border: 2px solid #0049AA;
      border-radius: 8px;

      font-family: 'Montserrat', 'Lato', 'Open Sans', 'sans-serif';
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: .22px;
      font-weight: 500;
      color: #0049AA;

      transition: all 0.35s ease;

      @media only screen and (max-width: 545px) {
        display: block;
        width: 100%;
        max-width: 275px;
        margin: 20px auto;
        font-size: 13px;
      }

      &:hover,
      &.is-active {
        opacity: 1;
        background: #0049AA;
        color: #fff;
      }

      &.has-tooltip {
        position: relative;
        padding-right: 65px;

        &.ghost:not(.is-active):not(:hover) {
          opacity: .30;
        }

        &:after {
          content: '';

          position: absolute;
          top: 50%;
          right: 22px;
          transform: translate( 0, -50% );

          width: 24px;
          height: 24px;
          background-image: url('/assets/images/icon-question.svg');
        }
      }
    }

    .cancel {
      @media only screen and (max-width: 545px) {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }

  .tooltip {
    margin-top: 15px;
    width: 455px;

    &.in {
      opacity: 1;
    }

    .tooltip-inner {
      min-width: 100%;
      padding: 15px 20px;
      margin: 0 0 -25px 0;
      border-radius: 8px;
      background: #EDF5FF;

      font-family: 'Lato', 'Open Sans', 'sans-serif';
      color: #231F20;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
    }

    & .tooltip-arrow {
      margin-top: -5px;
      margin-left: -15px;
      border-width: 0 15px 15px;
      border-bottom-color: #EDF5FF;
    }
  }
}