.sitemap {

    .grid-item {
        width: 100%;
        padding: 20px;
        margin-bottom: 40px;

        @media only screen and (min-width: $smallScreen) {
            width: 50%;
        }

        @media only screen and (min-width: $mediumScreen) {
            width: 33.33334%;
        }
    }

    a {
        color: $lightBlue;
        display: block;
        padding: 10px 20px;

        &:hover {
            background: $light;
        }
    }

    .site-map-list {
        margin: 10px 0 0;
        list-style: none;
        padding: 10px 0;
        background: $lightGrey;

        & > li {
        }
    }

    .site-map-sublist {
        margin: 0;
        list-style: none;
        padding: 0;

        a {
            padding: 10px 20px 10px 40px;
        }
    }

    .site-map-subsublist {
        margin: 0;
        list-style: none;
        padding: 0;

        a {
            padding: 10px 20px 10px 60px;
        }
    }
}