.rules-politics {

    .rules-title {
        @extend .smallTitle;
        font-weight: 700;
        padding: 12px 25px;
        margin: 0;
        color: $light;
        background: $blue;
    }

    .rules-subtitle {
        @extend .smallTitle;
        font-weight: 700;
        padding: 12px 25px;
        margin: 0;
        color: $dark;
        background: $lightGrey;
        border-top: .5px solid $grey;
        border-right: 1px solid $grey;
        border-left: 1px solid $grey;
    }

    .rules-document {
        -js-display: flex;
        display: flex;
        align-items: center;
        padding: 12px 50px 12px 25px;
        border-top: 1px solid $grey;
        border-right: 1px solid $grey;
        border-left: 1px solid $grey;
        background: url('/assets/images/rightArrowDarkBlue.svg') no-repeat right 25px center;

        &:last-child {
            border-bottom: 1px solid $grey;
        }

        .rules-document-numero {
            @extend .currentText;
            font-weight: 700;
            width: 75px;
            color: $dark;
        }

        .rules-document-name {
            @extend .currentText;
            width: calc(100% - 75px);
            color: $dark;
        }
    }
}