
.hero-contact {
  position: relative;

  .title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate( 0, -50% );

    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;

    color: #fff;

    z-index: 6;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    width: 100%;
    height: 100%;
    background: rgba( $blue, 0.70 );
  }
}

.contactContainer {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 75px 100px;

  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
    padding: 45px 35px;
  }
}

.formContainer {
  width: calc(100% - 280px);
  padding-right: 35px;

  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-right: 0;
    padding-bottom: 45px;
    border-bottom: 1px solid #f5f5f5;
  }
}

.form-errors {
  margin: 20px 0 20px;
  padding: 20px;
  border-radius: 5px;
  background: $error;
  list-style: none;
  display: none;
}

.main-form {
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  .main-form-label {
    display: block;
    padding-bottom: 10px;
    font-family: $fontMontserrat;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #231F20;
  }

  .main-form-row {
    padding-top: 25px;
  }

  .main-form-field {
    width: 100%;
    height: 45px;
    padding: 10px 20px;


    font-family: $fontLato;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #575757;

    border: none;
    background: #F5F5F5;
    border-radius: 8px;

    outline: none;

    &.main-form-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;       /* remove default arrow */
      background-image: url('/assets/images/form-select-arrow.svg');   /* add custom arrow */
      background-color: #FFF;
      background-repeat: no-repeat;
      background-position: right 15px   center;
      border: 1px solid #F5F5F5;
      padding-right: 45px;
    }

    &.main-form-textarea {
      min-height: 175px;
      padding: 20px;
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.block-aside-address {
  width: 280px;
  padding-top: 45px;
  padding-left: 35px;

  &.wo-margin {
    padding-top: 0;
    padding-left: 0;
  }

  .block-aside-address-info {
    border-bottom: 1px solid #f5f5f5;
    padding-top: 25px;
    padding-bottom: 25px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border: none;
    }
  }

  @media only screen and (max-width: 767px) {
    display: flex;
    width: 100%;
    padding-left: 0;
    justify-content: space-around;

    .block-aside-address-info {
      padding: 0;
      border: none;

      h4 {
        margin-top: 0;
      }
    }
  }

  @media only screen and (max-width: 549px) {
    flex-wrap: wrap;

    .block-aside-address-info {
      width: 100%;
      padding: 0;
      text-align: center;
      padding-bottom: 25px;
      border-bottom: 1px solid #f5f5f5;

      &:last-child {
        padding-top: 45px;
      }
    }
  }

  p, a {
    font-family: $fontLato;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #575757;
  }

  a {
    transition: all 0.35s ease;

    &:hover {
      color: $blue;
    }
  }
}

.googleMap {
  position: relative;
  width: 100%;
  height: 500px;
  background: #f5f5f5;

  @media only screen and (max-width: 1404px) {
    height: 375px;
  }

  @media only screen and (max-width: 767px) {
    height: 255px;
  }

}