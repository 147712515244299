.overlay {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    max-height: calc(100vh - 40px);
    width: calc(100vw - 40px);
    max-width: 800px;
    transform: translate(-50%,-50%);
    background: $light;
    padding: 40px;
    z-index: 1000;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.overlay-background {
    display: none;
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($dark, .5);
    z-index: 999;

    &:after {
        content: '';
        position: absolute;
        right: 20px;
        top: 20px;
        width: 40px;
        height: 40px;
        background: url('/assets/images/icon-close-light.svg') no-repeat center center / contain;
    }
}