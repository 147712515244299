.hero-carriere {
  position: relative;

  .title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate( 0, -50% );

    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;

    color: #fff;

    z-index: 6;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    width: 100%;
    height: 100%;
    background: rgba( $blue, 0.70 );
  }
}

.block-jobsSection {
  margin-bottom: 30px;

  .bigTitle {
    margin-bottom: 20px;
  }
}

.jobLink {
  position: relative;
  display: block;
  padding: 15px 45px 15px 20px;
  border: 1px solid $lightGrey;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 0 16px;
  transition: background ease-out .2s;

  @media only screen and (min-width: $smallScreen) {
    padding: 25px 60px 25px 20px;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    background: $darkBlue url('/assets/images/singleRightArrowLight.svg') no-repeat center center;

    @media only screen and (min-width: $smallScreen) {
      width: 40px;
    }
  }

  &:hover {
    background-color: $lightGrey;
  }

  &:last-child {
    margin-bottom: 0;
  }
}