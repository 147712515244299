.school-site {
  .main-nav-menu {
    .main-nav-link.has-children {
      background-position: right 25px top 13px;

      &.opened {
        background-position: right 25px top 19px;
      }
    }
  }

  .main-nav-site-wrapper {
    padding: 0;
  }

  // Translate right on nav.show and Mobile
  .main-nav.show + .main-nav-site-wrapper {
    @media (max-width: 1430px) {
      transform: translate(-300px, 0);
    }
  }

  // SideBar Menu Container
  .main-nav {
    left: initial;
    right: 0;
    transform: translate(300px, 0);

    &.show {
      transform: translate(0, 0);
    }
  }

  .articleMenu::after {
    display: none;
  }

  .eventItem {
    .currentText {
      a {
        &:after {
          @media only screen and (min-width: $smallScreen) {
            width: 40px;
            background-color: #213654;
          }
        }
      }
    }
  }
}
