// --------------------------------------------------
// Toolbar
// --------------------------------------------------

// Toolbar container
.toolbar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 70px;
  margin: 0;

  background: #095797;
}

// Toolbar nav links
.toolbar-links {
  padding: 0 10px;
  display: flex;
  min-width: 45%;

  @media (max-width: 1430px) {
    display: none;
  }

  a {
    display: block;
    padding: 0 10px 5px 10px;

    font-family: $fontMontserrat;
    color: white;
    font-weight: 500;
    text-decoration: none;
    font-size: 16px;

    transition: all 0.35s ease;

    @media (max-width: 1285px) {
      font-size: 12px;
    }

    &:hover {
      color: #fff;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

// Toolbar search btn
.toolbar-search-btn {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1430px) {
    flex: 1;
  }

  @media (max-width: 767px) {
    display: none;
  }

  [type="submit"] {
    display: none;
  }

  input[type="search"] {
    width: 0;
    margin: 0;
    padding: 5px 0;
    border: none;
    outline: none;
    appearance: none;
    color: #fff;
    background-color: transparent;
    border-bottom: 1px solid #fff;
    font-size: 13px;
    transition: all 0.25s ease;

    &::placeholder {
      color: #fff;
      opacity: .75;
    }
  }

  &.active input[type="search"] {
    width: 200px;
  }

  label {
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0 40px 0 20px;

    @media (max-width: 1430px) {
      margin-right: 20px;
    }
  }
}

// Burger btn ( Only for mobile )
.burger-btn {
  display: none;

  @media screen and (max-width: 1430px) {
    display: flex;
  }

  button {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    min-width: 40px;

    background: transparent;
    border: none;
    outline: none;

    cursor: pointer;
    padding: 0;
  }

  .lines {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );

    width: 65%;
    height: 2px;
    background: #FFF;

    &:before,
    &:after {
      content: '';

      position: relative;
      top: -7px;

      display: block;
      width: 100%;
      height: 2px;
      background: #FFF;

      transition: all 0.35s ease;
    }

    &:after {
      top: 5px;
    }
  }

  .label {
    display: inline-block;
    padding-left: 10px;

    font-family: $fontMontserrat;
    color: white;
    font-weight: 500;
    text-decoration: none;
    font-size: 14px;
  }
}

.main-nav.show + .main-nav-site-wrapper .burger-btn {

  .lines {
    height: 0;

    &:before,
    &:after {
      top: 0;
      transform: rotate(45deg);
    }

    &:after {
      top: -2px;
      transform: rotate(-45deg);
    }
  }
}

// Logo
.main-nav-logo {
  position: relative;
  display: flex;
  align-items: center;

  img {
    display: block;
    max-height: 70px;

    @media (max-width: 470px) {
      display: none;
    }
  }

  .toolbar-title {
    font-family: $fontMontserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    max-width: 194px;
    color: #FFFFFF;
    margin: 0 0 0 10px;

    @media (max-width: 470px) {
      margin-left: 20px;
    }

  }
}
