// --------------------------------------------------
// Base
// --------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: auto;
  font-size: 62.5%;
  padding: 0;
  margin: 0;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: $fontLato;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  color: $dark;
}

a {
  text-decoration: none;
}

// Helpers
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

select::-ms-expand {
  display: none;
}

.trajectoire-formation {
  width: 100%;
}

:root .color-yellow {
  color: #FCD25E;
}

:root .color-blue1 {
  color: #0049AA;
}

:root .color-blue2 {
  color: #00388A;
}
