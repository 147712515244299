.tag {
    display: inline-block;
    background: $yellow;
    padding: 2px 7px;
    font-family: $fontMontserrat;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: $darkBlue;
}