.recours-collectif-wrapper {
    display: none;
}

.recours-collectif {
    position: relative;
    width: 100%;
    -js-display: flex;
    display: flex;
    flex-direction: column;
    padding: 20px;

    @media only screen and (min-width: $smallScreen) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 24px 60px 24px 30px;
    }

    .currentText {
        width: 100%;
        margin-bottom: 20px;
        padding-right: 30px;

        @media only screen and (min-width: $smallScreen) {
            width: auto;
            max-width: 685px;
            margin-bottom: 0;
            padding-right: 0;
        }
    }

    .button:not(.yellowLight) {
        margin-bottom: 10px;

        @media only screen and (min-width: $smallScreen) {
            margin: 0 10px;
            min-width: 125px;
            text-align: center;
        }

        @media only screen and (min-width: $mediumScreen) {
            margin: 0 32px 0 40px;
        }
    }

    .button.yellowLight {
        min-width: 225px;
        text-align: center;
    }

    .close {
        position: absolute;
        width: 16px;
        height: 16px;
        right: 20px;
        top: 20px;
        background: url('/assets/images/icon-close-dark.svg') no-repeat center center / contain;
        cursor: pointer;

        @media only screen and (min-width: $smallScreen) {
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}