.school-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  
  .top-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px 40px;

    @media (max-width: 1023px) {
      padding: 20px 16px;
    }

    @media (max-width: 767px) {
      padding: 16px;
    }

    .main-nav-logo {
      flex: 1;
      width: 30%;
      display: flex;
      align-items: center;
      gap: 14px;

      @media (max-width: 767px) {
        display: none;
      }

      img {
        height: 30px;

        @media (max-width: 1150px) {
          display: none;
        }
      }

      span {
        color: var(--Black, #20201E);
        font-family: $fontRoboto;
        font-size: 13.127px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .school-info {
      flex: 1;
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      @media (max-width: 767px) {
        width: auto;
        align-items: flex-start;
        gap: 3px;
      }

      .info-subtitle {
        color: var(--Black, #20201E);
        font-family: $fontRoboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
        letter-spacing: 0.28px;
        margin: 0;

        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
      
      .info-name {
        color: var(--Black, #20201E);
        text-align: center;
        font-family: $fontRoboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
        letter-spacing: 0.4px;
        margin: 0;

        @media (max-width: 767px) {
          font-size: 18px;
        }
      }
    }

    .menu-bar {
      flex: 1;
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 32px;

      @media (max-width: 1023px) {
        gap: 16px;
      }

      .toolbar-search-btn-container {
        position: relative;
      }

      .toolbar-search-btn {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: flex;
        justify-content: flex-end;
        align-items: center;

      
        @media (max-width: 1430px) {
          flex: 1;
        }
      
        @media (max-width: 767px) {
          display: none;
        }
      
        [type="submit"] {
          display: none;
        }
      
        input[type="search"] {
          width: 0;
          margin: 0;
          padding: 5px 0;
          border: none;
          outline: none;
          appearance: none;
          color: #20201E;
          background-color: transparent;
          border-bottom: 1px solid #20201E;
          font-size: 13px;
          transition: all 0.25s ease;
      
          &::placeholder {
            color: #20201E;
            opacity: .75;
          }
        }
      
        &.active input[type="search"] {
          width: 200px;
        }
      
        label {
          position: relative;
          border: none;
          outline: none;
          cursor: pointer;
          margin: 0 0 0 20px;
        }
      }

      a {
        color: var(--Black, #20201E);
        font-family: $fontRoboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        letter-spacing: 0.28px;

        @media (max-width: 1150px) {
          display: none;
        }
      }

      .burger-btn {
        display: none;
      
        @media (max-width: 1150px) {
          display: flex;
        }
      
        button {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 40px;
          min-width: 40px;
      
          background: transparent;
          border: none;
          outline: none;
      
          cursor: pointer;
          padding: 0;
        }
      
        .lines {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate( -50%, -50% );
      
          width: 65%;
          height: 2px;
          background: #20201E;
      
          &:before,
          &:after {
            content: '';
      
            position: relative;
            top: -7px;
      
            display: block;
            width: 100%;
            height: 2px;
            background: #20201E;
      
            transition: all 0.35s ease;
          }
      
          &:after {
            top: 5px;
          }
        }
      }
    }
  }

  .bottom-bar {
    display: flex;
    align-items: center;
    gap: 32px;
    list-style: none;
    padding-bottom: 35px;
    margin: 0;

    @media (max-width: 1150px) {
      display: none;
    }

    .nav-item {
      position: relative;
      display: flex;

      &:has(.nav-submenu) {
        & > .nav-link {
          padding-right: 45px;

          &:after {
            content: '';
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PG1hc2sgaWQ9ImEiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0iI0Q5RDlEOSIgZD0iTTAgMGgyNHYyNEgweiIvPjwvbWFzaz48ZyBtYXNrPSJ1cmwoI2EpIj48cGF0aCBkPSJtMTIgMTYuMzc1LTYtNiAxLjQtMS40IDQuNiA0LjYgNC42LTQuNiAxLjQgMS40LTYgNloiIGZpbGw9IiMyMDIwMUUiLz48L2c+PC9zdmc+");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }

        &:hover {
          .nav-submenu {
            pointer-events: all;
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      .nav-link {
        color: var(--Black, #20201E);
        font-family: $fontRoboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        letter-spacing: 0.32px;
        padding: 10px;
      }
      
    }

    .nav-submenu {
      position: absolute;
      z-index: 10;
      top: 30px;
      left: 0;
      background: #fff;
      padding: 45px 5px 14px;
      min-width: 325px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      pointer-events: none;
      opacity: 0;
      transform: translateY(-20px);
      transition: all 0.2s ease;

      .nav-item {
        display: flex;

        .nav-link {
          width: 100%;
          padding: 9px 15px;
          border-radius: 5px;
          background-color: #fff;
          transition: background-color 0.25s ease;

          &:hover, &.is-active {
            background-color: #F7E69A;
          }
        }
      }
    }
  }
}