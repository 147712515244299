.section-404 {
    -js-display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 40px 20px;

    @media only screen and (min-width: $smallScreen) {
        padding: 50px;
    }

    .section-404-wrapper {
        -js-display: flex;
        display: flex;
        flex-direction: column;
        max-width: 824px;

        @media only screen and (min-width: $smallScreen) {
            flex-direction: row;
            align-items: stretch;
        }

        .section-404-image {
            background: url('/assets/images/404-icon.svg') no-repeat center top / contain;
            width: 100%;
            height: 100px;
            margin-bottom: 40px;

            @media only screen and (min-width: $smallScreen) {
                background-position: top center;
                width: 220px;
                height: auto;
                margin-bottom: 0;
            }
        }

        .section-404-text {
            width: 100%;

            @media only screen and (min-width: $smallScreen) {
                width: calc(100% - 220px);
            }

            .bigTitle {
                margin: 0 0 10px;
            }

            .smallTitle {
                margin: 0 0 24px;
            }

            .currentText {
                margin: 0 0 40px;

                img {
                    height: 20px;
                    vertical-align: sub;
                }
            }
        }
    }
}