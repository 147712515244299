.homeContent {
  -js-display: flex;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: $mediumScreen) {
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
  }
}

// Custom Zone
.homeSlider {
  position: relative;
  width: 100%;
  min-height: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  order: 1;

  @media only screen and (min-width: $smallScreen) {
    min-height: 430px;
  }

  @media only screen and (min-width: $mediumScreen) {
    width: calc(100% - 350px);
  }

  @media only screen and (min-width: $hugeScreen) {
    width: calc(100% - 600px);
  }

  &:after {
    display: none;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 44px;
    background: url('/assets/images/curveLeftLight.svg') repeat-y right bottom -300px;
    z-index: 3;

    @media only screen and (min-width: $mediumScreen) {
      display: block;
    }
  }

  .flickity-viewport {
    min-height: 100%;
  }

  .flickity-page-dots {
    bottom: 20px;

    .dot {
      width: 12px;
      height: 12px;
      border-radius: 10px;
      border: 2px solid $light;
      background-color: transparent;
      transition: background-color ease-out 0.2s;
      opacity: 1;

      &.is-selected {
        background-color: $light;
      }
    }
  }

  .flickity-button {
    background: none;
    padding: 0;
    width: 26px;
    height: 26px;

    &.next {
      right: 40px;
    }

    .flickity-button-icon {
      position: relative;
      left: initial;
      top: initial;
      width: 100%;
      height: 100%;
    }

    & > svg > path {
      fill: $light;
    }
  }

  .homeSlide {
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;

    &.closed {
      background: #AB362F url('/assets/images/schoolStatusClosed.svg') no-repeat top 20px right 40px / 60px;
    }

    &.opened {
      background: #5AA798 url('/assets/images/schoolStatusOpened.svg') no-repeat top 20px right 40px / 60px;
    }

    &.custom {
      background: transparent url('/assets/images/schoolStatusClosed.svg') no-repeat top 20px right 40px / 60px;

      &.red {
        background-color: #ab362f;
      }

      &.green {
        background-color: #42a699;
      }

      &.yellow {
        background-color: $yellow;
        background: $yellow url('https://cdn-contenu.quebec.ca/cdn-contenu/ico-bandeau.svg') no-repeat top 20px right 40px / 60px;

        .slideHeading, .slideText, .slideButton {
          color: #000;
        }
      }

      &.orange {
        background-color: #ff803d;
      }
    }

    &.with-image {
      background-position: center center;
      background-size: cover;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.5) 100%);
      }
    }

    & > * {
      position: relative;
      z-index: 2;
    }

    .slideHeading {
      width: calc(100% - 200px);
      max-width: 560px;
      font-family: $fontMontserrat2;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      letter-spacing: 0.225px;
      color: $light;
      margin: 0 0 20px;
    }

    .slideText {
      width: calc(100% - 200px);
      max-width: 560px;
      font-family: $fontMontserrat2;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.225px;
      color: $light;
      margin: 0;

      & > p {
        margin: 0 0 16px;
      }

      a {
        color: $darkBlue;
        font-weight: 700;
      }
    }

    .slideButton {
      display: inline-block;
      font-family: $fontMontserrat2;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.175px;
      color: $light;
      padding: 12px 24px;
      border: 2px solid $light;
      box-sizing: border-box;
      border-radius: 100px;
      transition: all ease-out 0.2s;

      &:hover {
        background-color: $light;
        color: $dark;
      }
    }
  }
}

.customZone {
  position: relative;
  width: 100%;
  min-height: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  order: 1;

  @media only screen and (min-width: $smallScreen) {
    min-height: 400px;
  }

  @media only screen and (min-width: $mediumScreen) {
    width: calc(100% - 350px);
  }

  @media only screen and (min-width: $hugeScreen) {
    width: calc(100% - 600px);
  }

  &:after {
    display: none;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 44px;
    background: url('/assets/images/curveLeftLight.svg') repeat-y right bottom -300px;
    z-index: 3;

    @media only screen and (min-width: $mediumScreen) {
      display: block;
    }
  }

  .show-more {
    content: 'Voir plus';
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    padding: 40px 10px;
    text-align: center;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }

  &.is-reduced {
    max-height: 50vh;
    overflow: hidden;

    .show-more {
      display: block;
    }

    &.closed .show-more {
      background: #8C312C;
    }

    &.opened .show-more {
      background: #1B7060;
    }

    &.custom.red .show-more {
      background: darken(#ab362f, 7);
    }

    &.custom.green .show-more {
      background: darken(#42a699, 7);
    }

    &.custom.yellow .show-more {
      background-color: darken($yellow, 7);
    }

    &.custom.orange .show-more {
      background-color: darken(#ff803d, 7);
    }
  }

  &.status {
    background: none !important;
    -js-display: flex;
    display: flex;
    flex-direction: column;

    .customZoneStatus {
      background: #AB362F url('/assets/images/schoolStatusClosed.svg') no-repeat center center / 150px;
      flex-grow: 1;
      min-height: 200px;
    }

    .customZoneText {
      flex-grow: 0;
      position: relative;
      background: #8C312C;

      @media only screen and (min-width: $smallScreen) {
        padding: 40px 80px 40px 40px;
      }
    }

    &.closed {
      .customZoneStatus {
        background: #AB362F url('/assets/images/schoolStatusClosed.svg') no-repeat center center / 150px;
      }

      .customZoneText, .customZoneLink {
        background-color: #8C312C;
      }
    }

    &.opened {
      .customZoneStatus {
        background: #5AA798 url('/assets/images/schoolStatusOpened.svg') no-repeat center center / 150px;
      }

      .customZoneText, .customZoneLink {
        background-color: #1B7060;
      }
    }

    &.custom {
      background: transparent url('/assets/images/schoolStatusClosed.svg') no-repeat center center / 150px;

      &.red {
        .customZoneStatus {
          background-color: #ab362f;
        }

        .customZoneText, .customZoneLink {
          background-color: darken(#ab362f, 7);
        }
      }

      &.green {
        .customZoneStatus {
          background-color: #42a699;
        }

        .customZoneText, .customZoneLink {
          background-color: darken(#42a699, 7);
        }
      }

      &.yellow {
        .customZoneStatus {
          color: $darkBlue;
          background-color: $yellow;
          background: $yellow url('https://cdn-contenu.quebec.ca/cdn-contenu/ico-bandeau.svg') no-repeat center center / 150px;
        }

        .customZoneText, .customZoneLink {
          color: $darkBlue;
          background-color: darken($yellow, 7);

          .bigTitle {
            color: $darkBlue;
          }

          .currentText {
            color: $darkBlue;
          }
        }
      }

      &.orange {
        .customZoneStatus {
          background-color: #ff803d;
        }

        .customZoneText, .customZoneLink {
          background-color: darken(#ff803d, 7);
        }
      }
    }
  }
}

.customZoneText {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: rgba(#0049AA, .7);

  @media only screen and (min-width: $smallScreen) {
    padding: 40px 145px 40px 40px;
  }

  @media only screen and (min-width: $mediumScreen) {
    padding: 20px 170px 20px 40px;
  }

  .bigTitle {
    margin: 0;
  }

  .currentText {
    margin-top: 15px;
  }

  .yellow {
    display: block;

    @media only screen and (min-width: $smallScreen) {
      display: inline;
    }
  }
}

.customZoneLink {
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100px;
  background: $blue url('/assets/images/customZoneArrows.svg') no-repeat center center / 30px;

  @media only screen and (min-width: $smallScreen) {
    display: block;
  }

  @media only screen and (min-width: $mediumScreen) {
    right: 35px;
  }
}

// Home Calendar
.homeCalendar {
  width: 100%;
  padding: 20px;
  order: 3;
  align-self: center;

  @media only screen and (min-width: $smallScreen) {
    padding: 40px;
  }

  @media only screen and (min-width: $mediumScreen) {
    width: 350px;
    padding: 40px 40px 40px 20px;
    order: 2;
  }

  @media only screen and (min-width: $hugeScreen) {
    width: 600px;
    padding: 40px 80px 40px 40px;
  }
}

.homeCalendarEvents {
  position: relative;
  -js-display: flex;
  display: flex;
  flex-direction: column;
}

.homeCalendarEvent {
  position: relative;
  -js-display: flex;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 20px 0 0;

  @media only screen and (min-width: $hugeScreen) {
    padding: 30px 0 0;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 18.5px;
    bottom: 0;
    width: 3px;
    background-color: $yellow;

    @media only screen and (min-width: $hugeScreen) {
      left: 28.5px;
    }
  }

  &:first-child {
    padding: 0;

    &:before {
      top: 50%;
    }
  }

  &:last-child {

    &:before {
      bottom: 50%;
    }
  }

  .date {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $darkBlue;
    background: $light;
    align-self: center;
    font-family: $fontMontserrat;
    font-size: 12px;
    line-height: 6px;
    color: $darkBlue;
    font-weight: 400;
    text-align: center;

    @media only screen and (min-width: $hugeScreen) {
      width: 60px;
      height: 60px;
      border: 2px solid $darkBlue;
      font-size: 16px;
      line-height: 10px;
    }

    span {
      display: block;
      font-size: 16px;
      line-height: 22px;
      font-weight: 700;
      margin-top: 1px;

      @media only screen and (min-width: $hugeScreen) {
        font-size: 22px;
        line-height: 26px;
        margin-top: 6px;
      }
    }
  }

  .currentText {
    width: calc(100% - 60px);
    align-self: center;
    padding-left: 15px;
    padding-right: 20px;

    a {
      display: block;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: calc(50% + 10px);
        width: 20px;
        height: 18px;
        transform: translateY(-50%);
        background: url('/assets/images/rightArrowDarkBlue.svg') no-repeat right center / auto 15px;
      }
    }
  }

  &.linked {

  }
}

// Event
.homeEvents {
  padding: 20px;
  background: $lightGrey;
  order: 2;
  width: 100%;

  @media only screen and (min-width: $smallScreen) {
    padding: 40px;
  }

  @media only screen and (min-width: $mediumScreen) {
    padding: 60px 40px;
    width: calc(100% - 400px);
    order: 3;
  }

  @media only screen and (min-width: $hugeScreen) {
    width: calc(100% - 650px);
  }
}

// Ctas
.homeCtas {
  order: 4;
  width: 100%;
  position: relative;
  -js-display: flex;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  background: url('/assets/images/curveMobileBlueTop.svg') no-repeat center top / auto 40px;

  @media only screen and (min-width: $smallScreen) {
    flex-direction: row;
    align-items: stretch;
    padding-top: 0;
    background: none;
  }

  @media only screen and (min-width: $mediumScreen) {
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
  }

  @media only screen and (min-width: $hugeScreen) {
    width: 650px;
  }

  &:after {
    display: none;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 44px;
    background: url('/assets/images/curveRightGrey.svg') repeat-y right top -88px;

    @media only screen and (min-width: $mediumScreen) {
      display: block;
    }
  }
}

.homeCta {
  width: 100%;
  padding: 80px 20px 20px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: top 20px center;
  background-size: auto 40px;

  &:nth-child(odd) {
    background-color: $blue;
  }

  &:nth-child(even) {
    background-color: $darkBlue;
  }

  //&.school {
  //    background-color: #003c86;
  //    background-image: url('/assets/images/homeCtaSchool.svg');
  //}
  //
  //&.work {
  //    background-color: #004da5;
  //    background-image: url('/assets/images/homeCtaWork.svg');
  //}
  //
  //&.notes {
  //    background-color: #0888d0;
  //    background-image: url('/assets/images/homeCtaNotes.svg');
  //}
  //
  //&.conseil {
  //    background-color: #0aa5fa;
  //    background-image: url('/assets/images/homeCtaConseil.svg');
  //}

  @media only screen and (min-width: $smallScreen) {
    width: 33.33334%;
    -js-display: flex;
    display: flex;
    align-items: center;
    padding: 100px 40px 40px;
    text-align: left;
    background-position: top 40px center;
  }

  @media only screen and (min-width: $mediumScreen) {
    width: 100%;
    height: 33.33334%;
    padding: 40px 40px 40px 140px;
    background-position: left 60px center;
    background-size: 50px auto;
  }

  @media only screen and (min-width: $hugeScreen) {
    background-size: 80px auto;
    padding: 40px 40px 40px 180px;
  }

  .mediumTitle {
    margin-bottom: 5px;
  }

  .currentText {
    margin-bottom: 15px;
  }
}

.homeGrid {
  margin: 5px;
  overflow: hidden;

  img {
    float: left;
    width: calc(50% - 2.5px);
    margin-right: 5px;
    margin-bottom: 5px;

    @media only screen and (min-width: $smallScreen) {
      width: calc(20% - 4px);
    }

    &:nth-child(2n+2) {
      margin-right: 0;

      @media only screen and (min-width: $smallScreen) {
        margin-right: 5px;
      }
    }

    &:last-child {
      margin-right: 0;
      width: 100%;

      @media only screen and (min-width: $smallScreen) {
        width: calc(20% - 4px);
      }
    }
  }
}

.school-home-layout {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  .homeSlider {
    width: 65%;
    order: 1;

    @media (max-width: 1023px) {
      width: 100%;
    }

    &:after {
      display: none;
    }
  }

  .homeCtas {
    width: 35%;
    order: 2;
    padding-top: 0;
    background: none;

    @media (max-width: 1023px) {
      width: 100%;
    }

    .homeCta {
      @media (min-width: 1024px) {
        padding: 40px 40px 40px 115px;
        background-position: left 35px center;
      }
  
      .mediumTitle {
        color: #FFF;
        font-family: $fontRoboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.7;
        letter-spacing: 0.2px;
        margin-bottom: 8px;
      }
    }

    &:after {
      display: none;
    }
  }

  .homeEvents {
    width: 65%;
    order: 3;
    padding: 80px 80px 80px 55px;

    &.is-full {
      width: 100%;
    }

    @media (max-width: 1023px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      padding: 40px 20px;
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 32px;
    }
  
    .mediumTitle {
      color: var(--Black, #20201E);
      font-family: $fontRoboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 2;
      letter-spacing: 0.225px;
    }

    .linkTypo {
      line-height: 2;
    }

    .eventItem .currentText a:after {
      width: 40px;
      background-color: #213654;

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .homeCalendar {
    width: 35%;
    order: 4;
    align-self: initial;
    padding: 80px 55px 80px 45px;

    @media (max-width: 1023px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      padding: 40px 20px;
    }

    &.is-full {
      width: 100%;
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 12px;
      margin-bottom: 48px;

      @media (max-width: 767px) {
        margin-bottom: 32px;
      }
    }

    .mediumTitle {
      color: var(--Black, #20201E);
      font-family: $fontRoboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 2;
      letter-spacing: 0.225px;
    }

    .linkTypo {
      line-height: 2;
    }

    .homeCalendarEvents {
      & > p {
        color: var(--Black, #20201E);
        font-family: $fontRoboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: 0.2px;
      }

      .homeCalendarEvent {
        padding-top: 60px;

        @media (max-width: 767px) {
          padding-top: 32px;
        }

        &:first-child {
          padding-top: 0;
        }

        &:before {
          width: 2px;
          left: 29px;
        }

        .date {
          width: 60px;
          height: 60px;
          border-radius: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #00388A;
          font-family: $fontMontserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          span {
            color: #00388A;
            text-align: center;
            font-family: $fontMontserrat;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: -6px;
            margin-top: 0;
          }
        }

        .currentText {
          color: var(--Black, #20201E);
          font-family: $fontRoboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 1.5;
          letter-spacing: 0.2px;
        }
      }
    }
  }
}

.school-home-grid {
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: stretch;
  padding: 4px 0;
  height: 21.5vw;

  @media (max-width: 767px) {
    padding: 2px 0;
  }

  .school-image-container {
    width: calc((100% - 8px) / 2);
    height: 100%;
    display: flex;

    @media (max-width: 767px) {
      width: calc((100% - 4px) / 2);
    }
  }

  .gallery-image-container {
    width: calc((100% - 12px) / 4);
    height: 100%;
    display: flex;

    @media (max-width: 767px) {
      width: calc((100% - 6px) / 4);
    }
  }

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
