// --------------------------------------------------
// Header - Side Navigation
// --------------------------------------------------

// Site Wrapper
body {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.main-nav-site-wrapper {
  position: relative;
  width: 100%;
  padding-left: 300px;
  padding-top: 70px;

  transition: all 0.35s ease;

  @media screen and (max-width: 1430px) {
    padding-left: 0;
  }
}

// Translate right on nav.show and Mobile
.main-nav.show + .main-nav-site-wrapper {

  @media screen and (max-width: 1430px) {
    transform: translate(300px, 0);
  }
}



// SideBar Menu Container
.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 300px;
  height: auto;
  min-height: 101vh;
  background: #FFF;
  box-shadow: 0 -5px 4px rgba(87, 87, 87, .33);

  transition: all 0.35s ease;
  padding-top: 70px;

  .iphone-close {
    display: none;
  }

  @media screen and (max-width: 1430px) {
    transform: translate(-300px, 0);

    &.show{
      transform: translate(0, 0);
    }

    .iphone-close {
      display: block;

      position: absolute;
      top: 35px;
      right: 25px;
      z-index: 5;

      width: 25px;
      height: 0;
      border: none;
      padding: 0;
      background: transparent;

      &:before,
      &:after {
        content: '';

        position: absolute;
        top: 50%;
        transform: translate( -50%, 0);

        display: block;
        width: 30px;
        height: 2px;
        background: #00388A;

        transform: rotate(45deg);
      }

      &:after {
        top: 0;

        transform: rotate(-45deg);
      }
    }
  }

  @media screen and (max-width: 374px) {

    width: 100vw;
    transform: translate(-100vw, 0);

    &.show{
      transform: translate(0, 0);
    }
  }
}

// Menu
.main-nav-menu {
  // Reset
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      padding: 0;
      margin: 0;

      li {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 20px;
          top: 0;
          bottom: 0;
          width: 4px;
          background-color: #FFEBB0;
        }
      }

      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }

  .main-nav-link.mobile-only {
    display: none;

    @media only screen and (max-width: 1430px) {
      display: block;
    }
  }

  // GLobal Links styles
  .main-nav-link a {
    position: relative;

    display: flex;
    align-items: center;

    padding: 12px 25px;

    font-family: $fontMontserrat;
    color: #231F20;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.03em;

    transition: all 0.35s ease;

    img {
      margin-right: 15px;
    }

    &:hover {
      background: rgba(0, 0, 0, .025);
    }
  }

  // First level links
  .main-nav-link > a {

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 25px;
      right: 25px;
      height: 1px;
      background: #f1f1f1;
    }

    &:after {
      top: auto;
      bottom: 0;
    }
  }

  // Second level
  .main-nav-link > ul {
    display: none; // By default is not visible. Will be anavled by JS
    padding: 15px 7px;
    background: $yellowLight;
    box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, .11);

    a {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 40px;
    }
  }

  // Add a plus sign for the .has-children .main-nav-link
  .main-nav-link.has-children {

    background-image: url('/assets/images/icon-plus.svg');
    background-position: right 25px top 25px;
    background-repeat: no-repeat;
    background-size: 12px;
    transition: background-color ease-out .2s;

    &.opened {

      background-image: url('/assets/images/icon-minus.svg');
      background-position: right 25px top 30px;
      background-color: $yellow;
    }
  }

  // The first and last link do not have a Border design
  .main-nav-link:first-child a {

    &:before {
      display: none;
    }
  }
  .main-nav-link:last-child a {

    &:after {
      display: none;
    }
  }

  // Opened link do not have a Border design
  .main-nav-link.opened a {

    &:after{
      display: none;
    }
  }
  .main-nav-link.opened + .main-nav-link a {

    &:before{
      display: none;
    }
  }
}

html[data-useragent*='MSIE 10.0'] .main-nav-submenu li a {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.social-links-container {
  width: 100%;
  padding: 25px 25px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  &.school {
    padding: 0;
    margin-top: 20px;

    .social-links {
      justify-content: flex-start;

      @media (max-width: 767px) {
        justify-content: center;
      }
    }
  }

  .social-links-text {
    font-family: $fontMontserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #515151;
    text-align: center;
    margin: 0 0 20px;
  }

  .social-links {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    .social-link {
      display: inline-block;
      width: 25px;
      height: 25px;

      &.custom {
        width: auto;

        img {
          height: 25px;
        }
      }

      &.cssrdn-sports {
        width: 100px;
        height: 40px;

        img {
          width: 100%;
        }
      }

      img {
        object-fit: contain;
        object-position: center center;
      }
    }
  }
}
