.heading {
    overflow: hidden;
    padding-bottom: 5px;
    border-bottom: 1px solid $grey;
    margin-bottom: 20px;

    @media only screen and (min-width: $hugeScreen) {
        padding-bottom: 15px;
    }

    &.sober {
        padding-bottom: 0;
        border-bottom: none;
    }

    .mediumTitle {
        float: left;
        margin: 0;
    }

    .linkTypo {
        display: block;
        float: right;
        line-height: 18px;

        @media only screen and (min-width: $hugeScreen) {
            line-height: 32px;
        }
    }
}