// Titles
.ultraBigTitle {
    font-family: $fontMontserrat;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: .5px;
    font-weight: 400;
    color: $light;
    text-shadow: 0px 2px 40px rgba(0, 0, 0, .5);
    margin: 0;
}

.xlargeTitle {
    font-family: $fontMontserrat;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: .5px;
    font-weight: 500;
    color: $dark;
    margin: 0;

    @media only screen and (min-width: $mediumScreen) {
        font-size: 32px;
        line-height: 40px;
    }
}

.bigTitle {
    font-family: $fontMontserrat;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: .3px;
    font-weight: 400;
    color: $dark;
    margin: 0;

    @media only screen and (min-width: $hugeScreen) {
        font-size: 24px;
        line-height: 36px;
    }
}

.mediumTitle {
    font-family: $fontMontserrat;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: .22px;
    font-weight: 400;
    color: $dark;
    margin: 0;

    @media only screen and (min-width: $hugeScreen) {
        font-size: 18px;
        line-height: 32px;
    }
}

.smallTitle {
    font-family: $fontMontserrat;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .89px;
    font-weight: 400;
    color: $dark;
    margin: 0;
}

// Text
.linkTypo {
    font-family: $fontMontserrat;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: .17px;
    font-weight: 400;
}

.currentText {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: .2px;
    font-weight: 300;
    margin: 0;

    @media only screen and (min-width: $hugeScreen) {
        font-size: 16px;
        line-height: 24px;
    }
}

.smallText {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    margin: 0;
}

.labelTypo {
    font-family: $fontMontserrat;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: .17px;
    font-weight: 400;
}

.labelLong {
    font-family: $fontMontserrat;
    color: $blue;
    font-size: 15px;
    letter-spacing: 0.02em;
    line-height: 20px;
    font-weight: 500;
}

.tagTypo {
    font-family: $fontMontserrat;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-transform: uppercase;
}

// Button
.button {
    display: block;
    width: 100%;
    text-align: center;
    float: left;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: .17px;
    padding: 10px 15px;
    border-radius: 100px;
    color: $darkGrey;
    background: $yellow;
    border: 2px solid $yellow;
    transition: all ease-out .2s;
    font-family: $fontMontserrat;
    font-weight: 700;
    cursor: pointer;
    outline: none;

    @media only screen and (min-width: $smallScreen) {
        width: auto;
        text-align: left;
    }

    @media only screen and (min-width: $hugeScreen) {
        font-size: 14px;
        line-height: 18px;
        padding: 10px 25px;
    }

    &:hover {
        background-color: $darkBlue;
        border-color: $darkBlue;
        color: $light;
    }

    &.light {
        color: $light;
        background: none;
        border-color: $light;

        &:hover {
            color: $darkGrey;
            background-color: $light;
        }
    }

    &.yellowLight {
        border-color: $yellow;
        background-color: $light;

        &:hover {
            background-color: $yellow;
            color: $light;
        }
    }
}

// Colors
.dark {
    color: $dark;
}

.light {
    color: $light;
}

.grey {
    color: $darkGrey;
}

.darkBlue {
    color: $blue;
}

.yellow {
    color: $yellow;
}

// Alignments
.leftalign {
    text-align: left;
}

.centeralign {
    text-align: center;
}

.rightalign {
    text-align: right;
}