.eventsList {
    -js-display: flex;
    display: flex;
    flex-direction: column;
}

.eventItem {
    position: relative;
    padding: 20px 20px 64px;
    background: $light;
    border-radius: 5px;
    margin-bottom: 20px;

    @media only screen and (min-width: $smallScreen) {
        padding: 20px 50px 20px 20px;
        margin-bottom: 30px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.grey {
        background-color: $lightGrey;
    }

    .tag {
        margin-bottom: 15px;
    }

    .smallTitle {
        font-weight: 700;
        margin-bottom: 10px;
    }

    .currentText {
        margin-bottom: 15px;

        a {
            font-weight: 700;

            &:after {
                content: 'Voir l\'article';
                line-height: 44px;
                text-align: center;
                font-size: 14px;
                color: $light;
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                height: 44px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                background: $darkBlue url('/assets/images/singleRightArrowLight.svg') no-repeat right 78px center / auto 15px;

                @media only screen and (min-width: $smallScreen) {
                    content: '';
                    position: absolute;
                    left: inherit;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 30px;
                    height: 100%;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    background: $darkBlue url('/assets/images/singleRightArrowLight.svg') no-repeat center center / auto 15px;
                }
            }
        }
    }

    .date {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: .78px;
        font-weight: 400;
    }

    &.event {

        .currentText {
            margin-top: 15px;
            margin-bottom: 0;
        }
    ;
    }
}