#loading {
    display: none;
    content: '';
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    background: rgba($dark, .75) url('/assets/images/loading.svg') no-repeat center center / 75px;
    border-radius: 10px;
}