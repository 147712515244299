.findFiltersWrapper {
    padding: 20px;

    @media only screen and (min-width: $smallScreen) {
        padding: 40px;
    }

    @media only screen and (min-width: $hugeScreen) {
        padding: 80px;
    }

    .bigTitle {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;

        @media only screen and (min-width: $hugeScreen) {
            margin-bottom: 15px;
        }
    }

    .smallTitle {
      font-weight: normal;
      font-size: 15px;
      line-height: 26px;
      margin-bottom: 20px;

      @media only screen and (min-width: $hugeScreen) {
        margin-bottom: 40px;
      }
    }
}

.filtersBar {
    -js-display: flex;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    clear: both;

    @media only screen and (min-width: $mediumScreen) {
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        background-color: $lightGrey;
        border-radius: 8px;
        margin-bottom: 40px;
    }
}

.filterBarLeft {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: $lightGrey;
    border-radius: 8px;
    flex-direction: column;
    max-height: 38px;
    overflow-y: hidden;
    display: none;

    @media only screen and (min-width: $smallScreen) {
        -js-display: flex;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-height: none;
        overflow-y: auto;
    }

    @media only screen and (min-width: $mediumScreen) {
        background-color: transparent;
        border-radius: 0;
    }
}

.filterBarItem {
    float: left;
    @extend .linkTypo;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;

    @media only screen and (min-width: $hugeScreen) {
        padding: 25px;
    }

    &.active {
        background-color: $yellow;
    }
}

#selectSchoolFilter {
    width: 100%;
    @extend .linkTypo;
    padding: 10px 30px 10px 10px;
    border-radius: 3px;
    cursor: pointer;
    min-height: 38px;
    background-color: $yellow;
    background-image: url('/assets/images/bottomArrowDarkBlue.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;

    @media only screen and (min-width: $smallScreen) {
        display: none;
    }
}

.filterBarRight {
    padding: 10px 0 10px 10px;

    @media only screen and (min-width: $mediumScreen) {
        padding: 8px 10px;
    }

    @media only screen and (min-width: $hugeScreen) {
        padding: 16px;
    }

    .linkTypo {
        margin: 0 8px 0 0;
        line-height: 32px;
        float: right;
    }
}

.filterDisplay {
    float: right;
    width: 38px;
    height: 32px;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 22px auto;
    margin-left: 8px;
    cursor: pointer;

    &.list {
        background-image: url('/assets/images/icon-filter-list.svg');
    }

    &.map {
        background-image: url('/assets/images/icon-filter-map.svg');
    }

    &.active {
        background-color: $yellow;
    }

    &.disabled {
        cursor: auto;
        opacity: .5;
    }
}

.filtersOrder, .filtersProgram {
    -js-display: flex;
    display: flex;
    flex-wrap: wrap;
}

.filterOrder {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    letter-spacing: .17px;
    color: $darkBlue;
    border: 1px solid $darkBlue;
    border-radius: 3px;
    padding: 5px 7px;
    margin: 0 10px 10px 0;
    cursor: pointer;
    transition: all ease-out .2s;

    @media only screen and (min-width: $smallScreen) {
        border-radius: 8px;
        padding: 12px 15px;
        margin: 0 15px 15px 0;
        border: 2px solid $darkBlue;
    }

    @media only screen and (min-width: $hugeScreen) {
        margin: 0 24px 24px 0;
        font-size: 14px;
    }

    &.active, &:hover {
        color: $light;
        background-color: $darkBlue;
    }
}

.schoolListWrapper {
    background: $lightGrey;
    padding: 20px;

    @media only screen and (min-width: $smallScreen) {
        padding: 40px;
    }

    @media only screen and (min-width: $mediumScreen) {
        padding: 40px 80px;
    }

    .heading {
        @media only screen and (min-width: $mediumScreen) {
            margin-bottom: 40px;
        }
    }
}

.schoolList {
    -js-display: flex;
    display: flex;
    flex-wrap: wrap;
}

.partialSchool {
    width: 100%;
    margin: 0 0 20px 0;
    background: $light;
    border-radius: 3px;
    overflow: hidden;

    @media only screen and (min-width: $smallScreen) {
        width: calc(33.33334% - (40px / 3));
        margin: 0 20px 20px 0;
        border-radius: 8px;

        &:nth-child(3n+3) {
            margin-right: 0;
        }
    }

    @media only screen and (min-width: $mediumScreen) {
        width: calc(33.33334% - (80px / 3));
        margin: 0 40px 40px 0;
    }

    @media only screen and (min-width: $hugeScreen) {
        width: calc(25% - 30px);

        &:nth-child(3n+3) {
            margin-right: 40px;
        }

        &:nth-child(4n+4) {
            margin-right: 0;
        }
    }

    .image {
        position: relative;
        display: block;
        height: 250px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        .logo {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 100px;
            height: 100px;
            background-color: $light;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 75px;
            border-radius: 75%;
        }

        .tags {
            position: absolute;
            left: 20px;
            bottom: 15px;
            right: 20px;
            -js-display: flex;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            @media only screen and (min-width: $mediumScreen) {
                left: 25px;
                bottom: 20px;
                right: 25px;
            }

            .tag {
                margin: 0 5px 5px 0;
            }
        }
    }

    .schoolInfos {
        padding: 20px;

        @media only screen and (min-width: $mediumScreen) {
            padding: 25px;
        }

        .title {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: .17px;
            font-weight: 600;
            margin: 0 0 8px;
            min-height: 36px;
        }

        .smallText {
            min-height: 34px;
        }
    }

    .link {
        display: block;
        padding: 12px;
        text-align: center;
        @extend .linkTypo;
        background: $darkBlue;
        color: $light;
        font-weight: 600;

        img {
            height: 12px;
            margin-left: 8px;
            vertical-align: middle;
        }
    }
}

.subFilterWrapper .main-form {

    -js-display: flex;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $smallScreen) {
        flex-wrap: wrap;
        flex-direction: row;
    }

    .main-form-row {

        @media only screen and (min-width: $smallScreen) {
            margin-right: 20px;
        }

        &.main-submit {
            align-self: flex-end;
        }
    }
}

.schoolListMap {
    display: none;

    .googleMap {
        height: 70vh;
    }
}

.schoolListTable {
    display: none;
    background: $lightGrey;
    padding: 20px;

    @media only screen and (min-width: $smallScreen) {
        padding: 40px;
    }

    @media only screen and (min-width: $mediumScreen) {
        padding: 40px 80px;
    }

    table {
        background-color: $light;
    }
}
