.ctaTrajectoire {
    display: block;
    padding: 30px 10px;
    background: $yellow;
    text-align: center;
    border-radius: 8px;

    @media only screen and (min-width: $hugeScreen) {
        padding: 60px;
    }

    img {
        max-width: 110px;
        max-height: 110px;
        margin-bottom: 15px;

        @media only screen and (min-width: $hugeScreen) {
            margin-bottom: 25px;
        }
    }
}