.school-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 55px 40px 35px;
  background-color: #213654;
  gap: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 50px;
  }

  .footer-column {
    width: calc((100% - 40px) / 3);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 1023px) {
      width: calc((100% - 20px) / 2);

      &:last-child {
        display: none;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      align-items: center;
    }

    .footer-heading {
      font-size: 15px;
      line-height: 20px;
      color: #fff;
      margin: 0 0 20px;

      @media (max-width: 767px) {
        text-align: center;
      }
    }

    .footer-address {
      font-size: 15px;
      line-height: 20px;
      color: #fff;
      font-weight: 300;
      margin: 0;

      @media (max-width: 767px) {
        text-align: center;
      }
    }

    .footer-facebook {
      display: flex;
      margin-top: 20px;

      svg {
        height: 32px;
      }
    }

    .info-rights {
      margin: 50px 0 0;
      font-size: 12px;
      line-height: 1;
      color: #fff;

      @media (max-width: 767px) {
        display: none;
        text-align: center;
      }

      &.only-mobile {
        display: none;

        @media (max-width: 767px) {
          display: block;
        }
      }
    }

    .footer-main-logo {
      display: flex;
      align-self: center;

      & > svg {
        height: 30px;
      }
    }
  }
}
