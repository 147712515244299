// --------------------------------------------------
// Variables
// --------------------------------------------------

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

// Fonts
$fontMontserrat: 'Roboto', 'Montserrat', sans-serif;
$fontMontserrat2: 'Montserrat', sans-serif;
$fontLato: 'Roboto', 'Lato', sans-serif;
$fontRoboto: 'Roboto', 'Lato', sans-serif;

// Colors
$light: #FFFFFF;
$dark: #000000;
$darkGrey: #575757;
$grey: #DADADA;
$lightGrey: #F5F5F5;
//$darkBlue_bk: #00388A;
//$blue_bk: #0049AA;
//$lightBlue_bk: #53A2FF;
//$yellow_bk: #FCD25E;
//$yellowLight_bk: #FFF8E5;
//$error_bk: #ffb1ba;

$darkBlue: #00388A;
$blue: #0049AA;
$lightBlue: #53A2FF;
$yellow: #FCD25E;
$yellowLight: #FFF8E5;
$success: #B3FFC0;
$error: #ffb1ba;

//$error: #f09686;
//$darkBlue: #223654;
//$blue: #095797;
//$lightBlue: #53A2FF;
//$yellow: #F8E69A;
//$yellowLight: #FFF8E5;

// Breakpoints
$tinyScreen: 320px;
$smallScreen: 768px;
$mediumScreen: 1024px;
$largeScreen: 1280px;
$bigScreen: 1440px;
$hugeScreen: 1680px;
