.alert {
    clear: both;
    width: 100%;
    padding: 20px;
    background-color: $lightGrey;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
    border-radius: 8px;

    &.alert-success {
        background-color: $success;
    }

    &.js-alert {
        display: none;
        margin-top: 25px;
    }
}