.paginationButtons {
    margin: 20px 0 0;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;

    @media only screen and (min-width: $smallScreen) {
        flex-direction: row;
        margin: 40px 0 0;
    }

    a {
        margin: 5px 5px;

        @media only screen and (min-width: $smallScreen) {
            margin: 0 5px;
        }
    }
}