.articleHeroBanner {
    position: relative;
    -js-display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 80px 40px;
    background-color: white;

    @media only screen and (min-width: $smallScreen) {
        padding: 120px 40px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($darkBlue, .5);
        z-index: 1;
    }

    .title {
        position: relative;
        z-index: 2;
        color: $light;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: .5px;
        margin: 0;

        @media only screen and (min-width: $smallScreen) {
            font-size: 40px;
            line-height: 46px;
            letter-spacing: .5px;
            max-width: 70%;
            text-align: center;
        }
    }
}

.articleWrapper {
    -js-display: flex;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;

    @media only screen and (min-width: $mediumScreen) {
        flex-direction: row;
        align-items: stretch;
    }
}

.articleMenu {
    position: relative;
    width: 100%;
    margin-top: 44px;
    background-color: $lightGrey;

    @media only screen and (min-width: $mediumScreen) {
        width: 320px;
        margin-top: 0;
    }

    @media only screen and (min-width: $hugeScreen) {
        width: 520px;
    }

    &:after {
        content: '';
        position: absolute;
        top: -44px;
        left: 0;
        right: 0;
        height: 44px;
        background: url('/assets/images/curveMobileGreyTop.svg') repeat-x center bottom;

        @media only screen and (min-width: $mediumScreen) {
            display: block;
            top: 0;
            left: inherit;
            right: 0;
            bottom: 0;
            height: auto;
            width: 44px;
            background: url('/assets/images/curveLeftLight.svg') repeat-y top 25% right;
        }
    }
}

.articleMenuSection {
    padding: 20px;
    border-top: 2px solid $light;

    @media only screen and (min-width: $smallScreen) {
        padding: 40px;
    }

    @media only screen and (min-width: $mediumScreen) {
        padding: 40px 60px 40px 40px;
    }

    @media only screen and (min-width: $hugeScreen) {
        padding: 60px 80px 60px 60px;
    }

    &:first-child {
        border-top: none;
    }

    .currentText {
        margin-top: 20px;
    }

    .ctaTrajectoire {
        margin-top: 40px;
    }
}

.articleSubLinks {
    margin: 20px 0 0;
    list-style: none;
    padding: 0;

    @media only screen and (min-width: $hugeScreen) {
        margin: 40px 0 0;
    }
}

.articleSubLink {
    position: relative;
    z-index: 0;
    display: block;
    @extend .smallTitle;
    padding: 20px 20px 20px 30px;
    background: url('/assets/images/menu-caret.svg') no-repeat left center;
    border-bottom: 1px solid $grey;

    @media only screen and (min-width: $hugeScreen) {
        padding: 24px 20px 24px 36px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: -20px;
        bottom: 0;
        left: -20px;
        z-index: -1;
        transition: background-color ease-out .2s;

        @media only screen and (min-width: $smallScreen) {
            right: 0;
        }
    }

    &.active {
        border-bottom: none;

        &:before {
            background-color: rgba($yellow, .2);
        }
    }

    &:hover:not(.active):before {
        background-color: rgba($yellow, .1);
    }
}

.articleContent {
    width: 100%;
    padding: 20px;

    @media only screen and (min-width: $smallScreen) {
        padding: 60px 40px;
    }

    @media only screen and (min-width: $mediumScreen) {
        width: calc(100% - 320px);
    }

    @media only screen and (min-width: $hugeScreen) {
        width: calc(100% - 520px);
        padding: 80px;
    }

    &.fullwidth {
        width: 100%;
    }

    strong {
      font-weight: 700;
    }
}
