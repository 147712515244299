// --------------------------------------------------
// Block
// --------------------------------------------------

// --------------------------------------------------
// Block Accordion
// --------------------------------------------------

.block-accordion-wrapper {
    clear: left;
}

.block-accordion {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 20px 0 40px 0;
    list-style-type: none;
    text-align: left;
    border: 1px solid $grey;
    border-radius: 8px;

    overflow-y: hidden;

    .block-accordion-title {
        padding-top: 40px;
        border-top: 2px solid $grey;
        font-weight: 600;
    }

    .block-accordion-row {
        position: relative;
        border-bottom: 1px solid $grey;
        transition: all 0.35s ease;

        &:hover {
            background: #fbfbfb;
        }

        &:last-child {
            border: none;
        }

        .block-accordion-label {
            position: relative;
            padding: 30px 65px 30px 25px;
            margin: 0;
            cursor: pointer;

            @extend .labelLong;
            margin-bottom: 0;

            @media only screen and (max-width: 1404px) {
                font-size: 12px;
                padding: 20px 60px 20px 20px;
            }

            .i-plus {
                display: block;
                position: absolute;
                top: 50%;
                right: 25px;
                transform: translate(0, -50%);
                width: 18px;
                height: 18px;
                margin-left: auto;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #0049AA;
                }

                &:after {
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    width: 2px;
                    height: 100%;
                }
            }
        }

        &.opened .block-accordion-label {

            .i-plus:after {
                display: none !important;
            }
        }

        .block-accordion-content {
            display: none;
            padding: 30px 25px;
            background: #EEF5FF;

            @media only screen and (max-width: 1404px) {
                padding: 20px 20px;
            }

            p {
                padding: 0;
                margin: 0 0 20px;

                font-family: $fontLato;
                font-size: 16px;
                line-height: 24px;
                color: #231F20;

                @media only screen and (max-width: 1404px) {
                    font-size: 14px;
                }
            }

            a:has(img) {
                display: flex;
                width: 100%;

                img {
                    width: 200px;
                }
            }

            ul li {
              font-family: $fontLato;
              font-size: 16px;
              line-height: 24px;
              color: #231F20;

              @media only screen and (max-width: 1404px) {
                  font-size: 14px;
              }
            }

            & > * {
                margin-bottom: 20px;
            }

        }

        &.opened .content {
            border-top: 1px solid $grey;
        }
    }
}

.block-alert {

}

.block-button {
    clear: both;
    margin: 0 0 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media only screen and (min-width: $smallScreen) {
        margin: 0 20px 40px 0;
    }
}

.block-cta {

}

// --------------------------------------------------
// Block Files (PDF)
// --------------------------------------------------

.block-file-title {
    clear: both;
    padding-top: 40px;
    border-top: 2px solid $grey;
    font-weight: 600;
}

.block-files {
    clear: both;
    position: relative;
    width: 100%;
    padding: 25px 0;
    margin: 0;
    list-style-type: none;
    text-align: left;
    -js-display: flex;
    display: flex;
    flex-wrap: wrap;

    .block-files-item {
        width: calc((100% / 3) - 3.5%);
        padding: 20px 30px;
        margin-right: 4%;
        margin-bottom: 4%;
        border: 2px solid $blue;
        border-radius: 8px;
        -js-display: flex;
        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
        background: #FFF;

        cursor: pointer;
        transform: scale(1);
        transition: all 0.35s ease;

        &:hover {
            transform: scale(.95);
            background: #fbfbfb;
        }

        &:nth-of-type(3n) {
            margin-right: 0;
        }

        @media only screen and (max-width: 1404px) {
            padding: 20px 10px;
        }

        @media only screen and (max-width: 767px) {
            width: calc((100% / 2) - 2.5%);
            &:nth-of-type(3n) {
                margin-right: 4%;
            }
            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }

        .block-files-label {
            @extend .labelLong;
            margin-bottom: 0;

            @media only screen and (max-width: 1404px) {
                font-size: 12px;
            }
        }
    }
}
html[data-useragent*='MSIE 10.0'] .block-files .block-files-item {
    flex-basis: 100%;
    overflow: hidden;
}

// --------------------------------------------------
// Block Form
// --------------------------------------------------

.block-form {

}

// --------------------------------------------------
// Block Form Links List
// --------------------------------------------------

.block-form-links {
    clear: both;
    position: relative;
    width: 100%;
    padding: 25px 0;
    margin: 0;
    list-style-type: none;
    text-align: left;

    .form-link a {
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 30px 50px 30px 30px;
        margin: 0 0 15px 0;
        border: 1px solid $grey;
        border-radius: 5px;

        @extend .smallTitle;
        font-weight: 600;
        line-height: 1;
        text-align: left;
        background: #FFF;

        cursor: pointer;
        transform: scale(1);
        transition: all 0.35s ease;

        overflow: hidden;

        @media only screen and (max-width: 767px) {
            font-size: 13px;
            font-weight: 500;
            padding: 20px 50px 20px 20px;
            margin: 0 0 10px 0;
            line-height: 16px;
        }

        &:hover {
            // transform: scale(.95);
            background: #fbfbfb;
        }

        .i-next {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 40px;
            background: $blue;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

// --------------------------------------------------
// Block Grid Images
// --------------------------------------------------
.block-grid-images {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    .block-grid-item {
        width: calc(25% - 20px);
        margin-right: 25px;
        margin-bottom: 25px;

        &:nth-of-type(4n) {
            margin-right: 0;
        }

        @media only screen and (max-width: 767px) {
            width: calc(50% - 15px);
            margin-right: 25px;
            margin-bottom: 25px;

            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    a {
        color: $lightBlue;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 400;
    }
}

.block-grid {
    clear: both;
    margin-bottom: 20px;
    width: 100%;
    overflow-x: scroll;

    @media only screen and (min-width: $smallScreen) {
        margin-bottom: 40px;
    }

    table {
        width: 100%;
    }

    td {
        padding: 7px 10px;
        font-size: 12px;
        line-height: 24px;
        border-top: 1px solid $lightGrey;
        border-right: 1px solid $lightGrey;

        @media only screen and (min-width: $smallScreen) {
            padding: 15px 25px;
            font-size: 16px;
        }

        &:first-child {
            border-left: 1px solid $lightGrey;
        }
    }

    thead {
        td {
            background: $darkBlue;
            color: $light;
            font-family: $fontMontserrat;
            letter-spacing: .89px;
            font-weight: 700;
        }
    }

    tbody {
        td {
            letter-spacing: .2px;

            &.link {
                background: url('/assets/images/rightArrowDarkBlue.svg') no-repeat right 20px center;
                padding-right: 40px;

                a {
                    color: $dark;
                }
            }
        }

        tr:last-child td {
            border-bottom: 1px solid $lightGrey;
        }
    }
}

.block-section-title {
    @extend .xlargeTitle;
    margin-bottom: 20px;
    clear: both;
}

.block-heading {
    @extend .bigTitle;
    margin-bottom: 20px;
    clear: both;
}

.block-image {
    clear: both;
    margin-bottom: 20px;

    @media only screen and (min-width: $smallScreen) {
        margin-bottom: 40px;
    }

    img {
        width: calc(100% + 40px);
        margin-left: -20px;

        @media only screen and (min-width: $smallScreen) {
            width: 100%;
            margin: 0;
        }
    }

    &.school {
        position: relative;

        .logo {
            position: absolute;
            top: 10px;
            right: -10px;
            width: 50px;
            height: 50px;
            background-color: #FFFFFF;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 35px;
            border-radius: 75%;

            @media only screen and (min-width: $smallScreen) {
                top: 15px;
                right: 15px;
                width: 100px;
                height: 100px;
                background-size: 75px;
            }
        }
    }
}

.block-list {
    clear: both;
    list-style: none;
    margin: 0 0 20px;
    padding: 0;

    @media only screen and (min-width: $smallScreen) {
        margin: 0 0 40px;
    }

    & > li {
        @extend .currentText;
        padding-left: 32px;
        position: relative;
        line-height: 20px;
        margin-bottom: 5px;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 11px;
            width: 16px;
            height: 4px;
            background: $yellow;
        }
    }

    a {
        color: $lightBlue;
    }
}

.block-list-num {
    clear: both;
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
    counter-reset: my-badass-counter;

    @media only screen and (min-width: $smallScreen) {
        margin: 0 0 40px;
    }

    & > li {
        @extend .currentText;
        -js-display: flex;
        display: flex;
        align-items: center;
        padding-left: 50px;
        position: relative;
        min-height: 34px;
        margin-bottom: 10px;

        @media only screen and (min-width: $smallScreen) {
            min-height: 44px;
            padding-left: 60px;
        }

        p {
            margin: 0;
        }

        &:before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            border: 2px solid $darkBlue;
            border-radius: 44px;
            line-height: 34px;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            font-family: $fontMontserrat;
            color: $darkBlue;
            content: counter(my-badass-counter);
            counter-increment: my-badass-counter;

            @media only screen and (min-width: $smallScreen) {
                width: 40px;
                height: 40px;
                line-height: 44px;
                font-size: 22px;
            }
        }
    }
}

.block-media {

}

//
// --------------------------------------------------
// Block - Split
// --------------------------------------------------

.block-split {
    clear: both;
    display: flex;
    padding-bottom: 45px;

    @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
    }

    &.block-split-left {
        .block-split-image {
            margin-right: 32px;
        }
    }

    &.block-split-right {
        flex-direction: row-reverse;

        .block-split-image {
            margin-left: 32px;
        }
    }

    .block-split-image {

        width: 40%;

        @media only screen and (max-width: 767px) {
            width: 100%;
            padding-bottom: 45px;
        }

        img {
            max-width: 100%;
            @media only screen and (max-width: 767px) {
                display: block;
                width: 100%;
            }
        }

    }

    .block-split-content {
        max-width: 60%;
        //padding-left: 30px;

        @media only screen and (max-width: 767px) {
            max-width: 100%;
            padding-left: 0;
        }
    }

    &.block-commissaire {
        .block-split-image {
            width: 100%;
            margin-right: 0;

            @media only screen and (min-width: $smallScreen) {
                width: 30%;
            }
        }

        .block-heading {
            font-size: 18px;
            line-height: 24px;
        }
    }
}

.block-split-content {
    clear: both;

    .block-split-title {
        padding: 0 0 15px 0;
        margin: 0;
        font-weight: 600;
    }

    p {
        padding: 0 0 0 0;
        margin: 25px 0 0 0;

        font-family: $fontLato;
        font-size: 16px;
        line-height: 24px;
        color: #575757;

        &:first-of-type {
            margin-top: 0;
        }

        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    ul {
        padding-left: 24px;

        li {
            color: $lightBlue;
            font-size: 20px;
        }
    }

    table {
        width: 100%;
        margin-top: 24px;

        tr {
            th,
            td {
                text-align: left;
                padding: 30px 24px;
            }

            th {
                background: $darkBlue;
                color: #FFF;
                text-transform: uppercase;
            }

            td {
                background: $lightGrey;
            }
        }
    }
}

// --------------------------------------------------
// Block Text
// --------------------------------------------------
.block-text {
    @extend .currentText;
    margin-bottom: 40px;
    clear: both;

    a {
        color: $lightBlue;
    }
}

// --------------------------------------------------
// Block Media Video
// --------------------------------------------------
.block-video {
    clear: both;
    position: relative;
    width: 100%;
    margin: 25px 0 45px 0;

    .block-video-iframe-wrap {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.block-address {
    clear: both;
    -js-display: flex;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @media only screen and (min-width: $smallScreen) {
        flex-direction: row;
        align-items: stretch;
    }

    .left-section {
        width: 100%;
        padding-right: 20px;
        margin-bottom: 20px;

        @media only screen and (min-width: $smallScreen) {
            width: 50%;
            margin-bottom: 0;
        }

        .smallTitle {
            margin-bottom: 16px;
        }

        .filtersOrder {
            margin-bottom: 40px;
        }

        .addressText {
            margin-bottom: 10px;
        }

        .phone {
            margin-bottom: 40px;
        }
    }

    .right-section {
        width: 100%;

        @media only screen and (min-width: $smallScreen) {
            width: 50%;
        }

        .googleMap {
            height: 100%;
            min-height: 200px;
            border: 1px solid $lightGrey;
        }
    }
}

.block-custom.coord-transp {

    .alert {
        display: none;
        margin: 10px 0 0;
        text-align: left;
    }
}
